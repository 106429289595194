import { useMemo, useCallback } from 'react';
import find from 'lodash/find';
import first from 'lodash/first';

import {
  FetchProductsFilters,
  ChangeProductsFiltersFunc
} from '../../../../../productsTypes';

import { useProductCategories } from '../../../../../hooks/useProductCategories';

import { getChangeProductsFiltersCategoriesData } from '../../../../../utils/getChangeProductsFiltersCategoriesData';

import { ProductCache } from '../../../../../ProductCache';

interface UseProductsIndexPageBreadcrumbsProps {
  productsFilters: FetchProductsFilters;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

function useProductsIndexPageBreadcrumbs({
  productsFilters,
  changeProductsFilters
}: UseProductsIndexPageBreadcrumbsProps) {
  const cacheKey = ProductCache.categoriesCacheKey();

  const { productCategories } = useProductCategories({ cacheKey });

  const currentParentCategory = useMemo(() => {
    const currentFiltersParentCategoryId = first(
      productsFilters?.productParentCategoryIds
    );

    return (
      find(productCategories, ['id', currentFiltersParentCategoryId]) || null
    );
  }, [productCategories, productsFilters]);

  const currentChildCategory = useMemo(() => {
    const currentFiltersChildCategoryId = first(
      productsFilters?.productCategoryId?.in
    );

    return (
      find(productCategories, ['id', currentFiltersChildCategoryId]) || null
    );
  }, [productCategories, productsFilters]);

  const handleResetCategoriesFilters = useCallback(() => {
    const { changeFilters, resetFilters } =
      getChangeProductsFiltersCategoriesData({
        productCategory: null,
        categories: []
      });

    changeProductsFilters(changeFilters, resetFilters);
  }, [changeProductsFilters]);

  const handleParentCategoryChangeFilters = useCallback(() => {
    const { changeFilters, resetFilters } =
      getChangeProductsFiltersCategoriesData({
        productCategory: currentParentCategory,
        categories: productCategories
      });

    changeProductsFilters(changeFilters, resetFilters);
  }, [changeProductsFilters, currentParentCategory, productCategories]);

  return {
    currentParentCategory,
    currentChildCategory,
    productCategories,
    handleResetCategoriesFilters,
    handleParentCategoryChangeFilters
  };
}

export default useProductsIndexPageBreadcrumbs;
