import { useCallback, useMemo, useEffect } from 'react';
import compact from 'lodash/compact';
import find from 'lodash/find';
import merge from 'lodash/merge';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import {
  FETCH_PRODUCT_BRANDS_SELECTED_QUERY,
  FetchProductBrandsSelectedQueryResponse
} from '../../../../../queries/fetchProductBrandsSelected.query';
import {
  FETCH_PRODUCT_BRANDS_QUERY,
  FetchProductBrandsQueryResponse
} from '../../../../../queries/fetchProductBrands.query';

import { useFinPaginatedCategories } from '../../../../../../categories/hooks/useFinPaginatedCategories';
import { useFinInfiniteCategories } from '../../../../../../categories/hooks/useFinInfiniteCategories';

import { ProductCache } from '../../../../../ProductCache';

import {
  CategoryID,
  FetchCategoriesScopes,
  FetchCategoriesSortTypes
} from '../../../../../../categories/categoriesTypes';

import {
  FetchProductsFilters,
  ProductClientID,
  ChangeProductsFiltersFunc,
  FetchFinProductCategoriesFilters
} from '../../../../../productsTypes';
import { ProductBrandsDataItem } from '../../ProductsBrandsFilter.types';

import { getProductClientIdsCacheKeyPart } from '../../../../../utils/getProductClientIdsCacheKeyPart';

const defaultInitialFilters = {
  scope: { eq: FetchCategoriesScopes.FACILITY_MANUFACTURERS }
};

const staleTime = 1000 * 60 * 60;

interface useProductsBrandsFilterProps {
  clientIds?: ProductClientID[];
  selectedIds: CategoryID[];
  name: string;
  productsFilters: FetchProductsFilters;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

const defaultFilterKeys: Array<keyof FetchFinProductCategoriesFilters> = [
  'scope',
  'productClientIds',
  'productCategoryId',
  'productParentCategoryId'
];

function useProductsBrandsFilter({
  clientIds,
  selectedIds = [],
  name,
  productsFilters,
  changeProductsFilters
}: useProductsBrandsFilterProps) {
  const categoryIds = productsFilters?.productCategoryId?.in;
  const parentCategoryIds = productsFilters?.productParentCategoryIds;

  const cacheKey = isEmpty(clientIds)
    ? ProductCache.brandsFilterCacheKey()
    : ProductCache.brandsLibraryFilterCacheKey(
        getProductClientIdsCacheKeyPart({ clientIds })
      );

  const defaultFilters: FetchFinProductCategoriesFilters = merge(
    {},
    defaultInitialFilters,
    isEmpty(clientIds) ? null : { productClientIds: clientIds },
    isEmpty(categoryIds) ? null : { productCategoryId: { in: categoryIds } },
    isEmpty(parentCategoryIds)
      ? null
      : { productParentCategoryId: { in: parentCategoryIds } }
  );

  const {
    categories: productBrands,
    categoriesError: productBrandsErrorMessage,
    categoriesFetched: productBrandsFetched,
    categoriesIsPlaceholderData: productBrandsIsPlaceholderData,
    isFetchingNextCategoriesPage: productBrandsFetchingNextPage,
    categoriesFilters: productBrandsFilters,
    hasNextCategoriesPage: hasNextProductBrandsPage,
    loadMoreCategories: loadMoreProductBrands,
    filterCategories: filterProductBrands,
    changeCategoriesFilters: changeProductBrandsFilters
  } = useFinInfiniteCategories<FetchProductBrandsQueryResponse>({
    cacheKey,
    query: FETCH_PRODUCT_BRANDS_QUERY,
    initialFilters: defaultFilters,
    initialSort: [FetchCategoriesSortTypes.LOCALIZED_NAME_ASC]
  });

  useEffect(() => {
    if (
      !isEqual(defaultFilters, pick(productBrandsFilters, defaultFilterKeys))
    ) {
      filterProductBrands({
        ...defaultFilters,
        ...pickBy(
          productBrandsFilters,
          (value, key) => !includes(defaultFilterKeys, key)
        )
      });
    }
  }, [productBrandsFilters, defaultFilters, filterProductBrands]);

  const {
    categories: productBrandsSelected,
    categoriesError: productsBrandsSelectedErrorMessage,
    categoriesFilters: productsBrandsSelectedFilters,
    changeCategoriesFilters: changeProductBrandsSelectedFilters
  } = useFinPaginatedCategories<FetchProductBrandsSelectedQueryResponse>({
    cacheKey: ProductCache.brandsSelectedFilterCacheKey(),
    query: FETCH_PRODUCT_BRANDS_SELECTED_QUERY,
    initialFilters: {
      scope: { eq: FetchCategoriesScopes.FACILITY_MANUFACTURERS },
      id: { in: selectedIds }
    },
    options: {
      staleTime,
      keepPreviousData: true
    }
  });

  useEffect(() => {
    if (!isEqual(selectedIds, productsBrandsSelectedFilters.id?.in)) {
      changeProductBrandsSelectedFilters({
        id: { in: selectedIds }
      });
    }
  }, [
    changeProductBrandsSelectedFilters,
    selectedIds,
    productsBrandsSelectedFilters.id?.in
  ]);

  const handleProductBrandsFilterSearch = useCallback(
    (input: string) => {
      changeProductBrandsFilters(
        { name: { ilike: input } },
        input ? [] : ['name']
      );
    },
    [changeProductBrandsFilters]
  );

  const productBrandsFilterSelectedData = useMemo(() => {
    const selectedFetchedData = productBrandsSelected.map((category) => ({
      id: category.id as string,
      label: category.name
    }));

    const selectedData = compact(
      selectedIds.map((id) => find(selectedFetchedData, ['id', id]))
    );
    return selectedData;
  }, [productBrandsSelected, selectedIds]);

  const productsBrandsFilterData = useMemo<ProductBrandsDataItem[]>(() => {
    return productBrands.map((category) => ({
      id: category.id as string,
      label: category.name
    }));
  }, [productBrands]);

  const handleChange = useCallback<
    (
      changedFilters: { [name: string]: string[] | undefined },
      removeFilters: string[]
    ) => void
  >(
    (changedFilters, removeFilters) => {
      changeProductsFilters(
        { [name]: { in: changedFilters?.[name] || [] } },
        removeFilters
      );
    },
    [changeProductsFilters, name]
  );

  return {
    productBrandsFilterData: productBrands,
    productBrandsFilterSelectedData,
    productBrandsFilter: productBrands,
    productsBrandsFilterData,
    productBrandsFilterErrorMessage: productBrandsErrorMessage,
    productsBrandsFilterSelectedErrorMessage:
      productsBrandsSelectedErrorMessage,
    productBrandsFilterFetched: productBrandsFetched,
    productBrandsFilterIsPlaceholderData: productBrandsIsPlaceholderData,
    productBrandsFilterFetchingNextPage: productBrandsFetchingNextPage,
    hasNextProductBrandsFilterPage: hasNextProductBrandsPage,
    productBrandsFilterSearchValue: productBrandsFilters?.name?.ilike,
    handleProductBrandsFilterSearch,
    loadMoreProductBrandsFilter: loadMoreProductBrands,
    handleChangeProductsBrandsFilter: handleChange
  };
}

export default useProductsBrandsFilter;
